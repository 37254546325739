var cur_template = php_vars.template_name;
var page_id = php_vars.page_id;
var curr_url = php_vars.curr_url;
if(typeof loadStyle == 'undefined'){ 
  function loadStyle(url) {
    var head = document.getElementsByTagName('HEAD')[0];
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    head.appendChild(link);
}
}
function read_cookie_ajax(cookie_step,passedurl){ 
    var request = new XMLHttpRequest();
    request.open("GET", path+'/wp-content/ajax-call/read_cookie_ajax_call.php?cookie_step='+cookie_step+'&passed_url='+passedurl+'&'+(new Date()).getTime(), true);
    request.setRequestHeader("x-robots", "noindex, nofollow");            
    request.send();
                request.onload = function() {
                    var output = request.responseText;
                    var obj = JSON.parse(output);					
					if(cookie_step == 'read_ck_lp' && (obj.lp_url !== '')){
					  $(".nerd_auths").attr("href", obj.lp_url);
					} 
    }            
}

(function() {
  var runPreFetch = true;
  //window.addEventListener("load", function(){
    if(runPreFetch){
      runPreFetch = false;
      var pre_url = "/getwpprefetchurl"; 		
      var request = new XMLHttpRequest();
      request.open("GET", pre_url, true);
      request.send();
      request.onload = function() {
        var obj = request.responseText;
        //var obj = JSON.parse(output);	
        var prefetchURLs = obj.split("|");
        prefetchURLs.forEach(function (u) {
          var linkTag = document.createElement('link');
          linkTag.rel = 'prefetch';
          linkTag.crossorigin = 'anonymous';
          linkTag.href = u;
          document.body.appendChild(linkTag);
        });
      }
    }    
  //});		
})();
	
let userstage_apival = false;	
var intervalId = setInterval(function () {
        if(document.cookie.match(/^(.*;)?\s*_sptd\s*=\s*[^;]+(.*)?$/)  && !userstage_apival){	
				var ustage_url = "/api/v1/generic/updatewpuserstage"; 		
				var urequest = new XMLHttpRequest();
				urequest.open("GET", ustage_url, true);
                urequest.send();
                urequest.onload = function() {
                    var userstage_val = urequest.responseText;
                    //var userstage_val = JSON.parse(userstage);
				}
				userstage_apival = true;
		}
		if(userstage_apival === true){
		    clearInterval(intervalId);
		 }
}, 300);
    
jQuery(document).ready(function ($) {

window.onpageshow = function(event) {
	$(".table-of-contents a").removeClass("toc-active");
    $(".table-of-contents a").parent('li').removeClass("toc-active");
}
if(is_home.is_home==1) {var w_width = $(window).width();
if (w_width <= 767) {
  $('body').on('touchstart', function(e) {
    $('.home_slider_header_tab').slick('slickPlay');
  });
}}
//$(".hero-section").on("click", function (e) {window.location.href=$(".creat-resume-button").attr('href');});
/*$(window).on("load", function () {	
 var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
 var integrations = {
                "All": false,
                "Mixpanel": true,
                "Segment.io": true,
                "Google Analytics": true
            };           
 if(is_lp.is_lp==1){integrations.Blueshift = true}
  var loadOptions = {
                integrations: integrations
            };
 analytics.load(config_var, loadOptions);
 analytics.page();
 }	
});*/

$(".table-of-contents a,.chronological-maintable a").on("click", function (e) {
 e.preventDefault(); 
 $(this).addClass("toc-active");
 $(this).parent('li').addClass("toc-active");
 var ipad = !!navigator.platform && /iPad|iPod/.test(navigator.platform);
 var Mac = !!navigator.platform && /Mac/.test(navigator.platform);
 var android = navigator.userAgent.toLowerCase().indexOf('android');
 var href=$(this).attr('href');var ipad_min=0;
 var iphone = !!navigator.platform && /iPhone/.test(navigator.platform);
 if(ipad){ipad_min=-70;}  
 if(iphone || android > -1){ipad_min=10;} 
 var ua = navigator.userAgent.toLowerCase(); 
if (ua.indexOf('safari') != -1) { 
  if (ua.indexOf('chrome') > -1) {
  } else {
   if(Mac){ipad_min=-70;}
  }
}
 $('html, body').animate(
    {
      scrollTop: ($($(this).attr('href')).offset().top) + ipad_min      
    },
   200
  )
 return false; 
});
$(".single_post_share_copy").on("click", function (e) {
    e.preventDefault();
    $(this).find("a").addClass('expand-copy');    
    var ipad = !!navigator.platform && /iPad|iPod|Mac|iPhone/.test(navigator.platform);
	if(ipad){$('#link-copy-url').get(0).setSelectionRange(0,9999), document.execCommand("copy");}else
	{ $('#link-copy-url').select(), document.execCommand("copy");}	   
    setTimeout(function(){
      $(".single_post_share_copy a").removeClass('expand-copy');            
    }, 1000);    
    return false;
  }); 
  // if(cur_template = 'home-page-migrated.php' || curr_url.includes('/landings/') || cur_template == 'legal-pages.php'){
	// read_cookie_ajax("read_ck_lp","");	
  // }  
 if(cur_template == 'home-page-migrated.php' && (document.cookie.match(/^(.*;)?\s*fstory\s*=\s*[^;]+(.*)?$/) == null)){
	  var y = Math.floor((Math.random() * 10) + 1);
	  var x = y % 2;
	  var fstory_val  = x == 0 ? true : false;
		document.cookie = "fstory="+fstory_val+"; path=/";		
	}   
});
function readCookieVal(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ')
          c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0)
          return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function createNewCookieDomain(name, value, days) {
  if (days) {var date = new Date();date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));var expires = "; expires=" + date.toGMTString();
  } else
      var expires = "";
  document.cookie = name + "=" + value + expires + ";path=/";
}

/** start: Code migrated from ccpa.js */
function call_lookup_api(){
  //Javascript Ajax
  var request = new XMLHttpRequest();
  request.open('GET', path+'/wp-content/ajax-call/ccpa_lookup_ajax_call.php', true);
  request.onload = function() {
      if (this.status >= 200 && this.status < 400) {
          var obj = JSON.parse(this.response);
          check_api_response(obj.isEUUser);
      } else {
          check_api_response('error');
      }
  };
  request.onerror = function() {
  // There was a connection error of some sort
  };
  request.send();
}
function check_api_response(isEUUser){ 
	if(isEUUser == 1){ 
		if(display_dnssi_link  ){
		    $('ul#menu-about li:last-child').removeClass('hide'); 
		}
		document.querySelector(".dnssi_link") &&  (document.querySelector(".dnssi_link").innerHTML = '<a href="/manage-dnssi" rel="nofollow">here</a>');
	}
	if(legal_pages == 1 && isEUUser == 1){ 
		$('ul#menu-about').append('<li><a target="_blank" href="/ccpa/personal-info">CCPA</a></li>');
		$('#ccpa_check').attr('href','/ccpa/personal-info');
		// if(!readCookieVal('cbnr'))
		// {
		// 	// ccpa_banner();			
		// }
		return;
	}	
    // if (isEUUser == 1) {
    //     // ccpa_banner();
    // } else if (isEUUser == 0) {
    //     //createCookie('cbnr', '0', 365);
    // }
    // if (isEUUser == 'error'){- 
    //         //ccpa_banner();
    // }
}
if((typeof legal_pages !== 'undefined' && legal_pages == 1) || (typeof display_dnssi_link !== 'undefined' && display_dnssi_link)){  
    call_lookup_api();
}
/** end: Code migrated from ccpa.js */



/**
 * Segment events code
 */

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
  else return null;
}

// get the device type
function getUserDeviceType() {
  var DeviceType;
  if (navigator.userAgent.match(/iPad|Tablet|PlayBook/i))
  {
      DeviceType = 'tablet';
  } else if (navigator.userAgent.match(/Mobile|Android|webOS|iPhone|iPod|Blackberry/i))
  {
      DeviceType = 'mobile';
  } else {
      DeviceType = 'desktop';
  }
  return DeviceType;

}

var _spt = getCookie("_spt");
var visitorType1 = (_spt == null || _spt == '') ? "New" : "Returning";
var portal = 'Wordpress', platform = 'Web', visitorType = visitorType1;
var UserStatus =  getCookie("UserStatus");
var loginStatus = UserStatus ? UserStatus.IsUserLoggedIn : false;
var visitId = getCookie("_sptd");

window.analyticsConsentForHeaderCTA = function(){
  
      var header_cta = document.getElementsByClassName("hero_cta_video_tracking");
      // video CTA tracking            
      if(!!header_cta){     
          for (var i = 0; i < header_cta.length; i++) { 
              // Attach a click event listener to each element
              header_cta[i].addEventListener("click", function(e) {
                if(analytics){ // check for analytic load
                  var anchorValueHeaderCta = this.innerText;
                  var headerCtahrefValue = 'video URL';
                  var ctaPosition = this.getAttribute("data-blade_position");// Ex: Hero Blade
                  RHUSTrackEvents('landing page CTA click', { 'position': 'hero blade', 'cta name': anchorValueHeaderCta, 'destination url': headerCtahrefValue});
                }
          
              });
          }       
      }
  
}

analyticsConsentForHeaderCTA();

function RHUSTrackEvents(event_name, Event_Property, Event_Property_Value){
  var defaultProps = {
      'Portal': portal,
      'Platform': platform,
      'Login_Status': loginStatus,
      'Visitor_Type': visitorType,
      'url': document.location.href,
      'user_agent': navigator.userAgent,
      'Title': document.title,
      'path': window.location.pathname,
      'Referrer': document.referrer,
      // 'context_page_url': document.URL,
      // 'properties_Platform': 'Web',
      // 'properties_Portal': 'Wordpress',
      '$screen_height': screen.height,
      '$screen_width': screen.width,
      'properties_device_type': getUserDeviceType(),
      'visitId': visitId,
  };

  if(typeof Event_Property == 'object') {
      var allProps = {
          ...defaultProps,
          ...Event_Property
      };
      
  } else {
      var allProps = defaultProps;
      allProps[Event_Property]= Event_Property_Value;
  }				
  analytics.track(event_name, allProps);                
}  